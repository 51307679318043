<template>
  <main class="home">
    <router-link v-for="g in $games" :key="g.slug" :to="`/${g.slug}/news`">
      {{ g.name }}
    </router-link>
  </main>
</template>

<script>
export default {
};
</script>


